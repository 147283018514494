import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Container from "components/Container";
import Row from "components/Row";
import Col from "components/Col";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "layout"
    }}>{`Layout`}</h1>

    <Playground __position={0} __code={'<Container>\n  <Row>\n    <Col>Column 1</Col>\n    <Col>Column 2</Col>\n  </Row>\n</Container>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Container,
      Row,
      Col,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container mdxType="Container">
    <Row mdxType="Row">
      <Col mdxType="Col">Column 1</Col>
      <Col mdxType="Col">Column 2</Col>
    </Row>
  </Container>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/Layout/" target="_blank">Reactstrap Layout</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      